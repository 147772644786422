@import "../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;700&family=Noto+Serif+Display:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap");

.app-header {
  color: $brandDark;
  padding: 0px 40px;
  font-family: "Cormorant Garamond", serif;
  width: 120px;

  &__link {
    text-decoration: none;
    color: inherit;
  }

  & h1 {
    font-size: 3.3rem;
  }

  &__capital {
    font-size: 4.2rem;
  }
}

.menu-button {
  position: fixed;
  top: 10px;
  color: $brandDark;
  background-color: $background-light;
  font-weight: 700;
  border: 1px solid $brandDark;
  padding: 8px;
  outline: none;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background-color: $background-light;
    transition: 200ms;
  }
}

@media (max-width: 700px) {
  .app-header {
    width: 80%;
  }
}
