@import "../../variables.scss";

.news-item-small {
  background-color: $background-light;
  padding: 20px;
  margin-top: 20px;
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    text-align: center;
    white-space: pre; /* CSS 2.0 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3.0 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -moz-pre-wrap; /* Mozilla */
    word-wrap: break-word; /* IE 5+ */
  }

  &__image {
    overflow: hidden;
    height: 150px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 700px) {
  .news-item-small {
    height: fit-content;
    align-items: center;
    &__header {
      flex-direction: column;
      align-items: center;
    }

    &__title {
      width: 100%;
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }
}
