@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;700&family=Noto+Serif+Display:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap");
body {
  background-color: #ff9966;
  color: $brandLight;
  font-family: "Lora", serif;
}

.app-container {
  display: flex;
  height: 100%;

  &__content {
    flex: 1;
  }
}

.contact-page {
  &__image {
    max-height: 40vh;
  }
  & a {
    color: $brandDark !important;
  }

  &__detail {
    display: flex;
    align-content: center;
    color: $brandDark !important;

    & span {
      margin-left: 5px;
    }
  }
}

.title {
  font-family: "Cormorant Garamond", serif;
  font-size: 2.3rem;
}

.button {
  background-color: $brandDark;
  color: $brandLight;
  outline: none;
  border: none;
  padding: 10px;
  transition: 300ms;

  &--light {
    background-color: $brandDark;
    color: $brandLight;
    text-decoration: none;
    padding: 5px;
    border-radius: 10px;
    margin-top: 30px;
  }

  &:hover {
    background-color: $background-light;
    color: $brandDark;
    transition: 300ms;
    cursor: pointer;
  }
}

.button-group {
  & button {
    margin: 2px;
  }
}

.biog-page {
  display: flex;
  flex-wrap: wrap;

  &__text {
    flex: 1;
    min-width: 500px;
  }

  &__image {
    width: 30%;
    min-width: 300px;
    margin-right: 15px;

    & img {
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .app-container {
    flex-direction: column;
    width: 100%;
  }
  .biog-page {
    flex-direction: column;

    &__text {
      min-width: 100%;
      padding: 10px;
    }
    &__image {
      width: 100%;
    }
  }
}

.homepage {
  background-color: $background-transparent;
}

@media (max-width: 1200px) {
  .homepage {
    &__middle {
      flex-direction: column;

      &__news {
        width: 100%;
      }
    }
  }
}

.page-content {
  padding: 50px;
  animation: pageEntrance 300ms ease-in-out;
  color: $brandDark;
}

@media (max-width: 700px) {
  .page-content {
    padding: 10px;
  }
}

@keyframes pageEntrance {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.interview {
  position: relative;
  margin-bottom: 50px;
  background-color: $background-light;
  padding: 50px;

  &__cd-cover {
    width: 200px;
    float: right;
    margin-left: 30px;
    margin-bottom: 30px;
  }
}

.recording-details {
  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  &__cover {
    width: 30%;
    min-width: 300px;
    margin-right: 20px;

    & img {
      width: 100%;
    }
  }

  &__meta {
    display: flex;
    padding: 5px;
    align-items: center;
    margin-bottom: 20px;
    height: 75px;
  }

  &__details {
    background-color: #fca376be;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 350px;
  }
  &__award {
    height: 100%;
    margin-right: 20px;
  }

  &__videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__video {
      width: 20%;
      height: 200px;
      min-width: 300px;
      margin: 8px;
      overflow: hidden;

      & iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.divider {
  height: 1px;
  background-color: $brandDark;
  width: 80%;
  margin: 50px auto;
}

.news-item {
  &--home {
    width: 30%;
    margin: 8px;
  }
}

@media (max-width: 1200px) {
  .news-item {
    &--home {
      width: 40%;
    }
  }
}
@media (max-width: 1000px) {
  .news-item {
    &--home {
      width: 100%;
    }
  }
}

.videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__video {
    width: 20%;
    min-width: 300px;
    height: 200px;
    overflow: hidden;
    margin: 8px;

    & iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__homepage-video {
    width: 45%;
    min-width: 300px;
    overflow: hidden;
    margin: 8px;
    & iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .videos-container {
    &__homepage-video {
      width: 100%;
    }
  }
}
