@import "../../variables.scss";

.news-item {
  background-color: $background-light;
  padding: 20px;
  margin-top: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    width: 50%;
    font-family: "Cormorant Garamond", serif;
  }

  &__image {
    width: 40%;
    min-width: 200px;

    flex: 1;
  }

  & iframe {
    max-width: 100%;
  }
}

@media (max-width: 820px) {
  .news-item {
    &__title {
      width: 100%;
    }
  }
}
