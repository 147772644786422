@import "../../variables.scss";

.side-drawer {
  position: sticky;
  top: 0px;
  width: 100px;
  padding: 20px 40px;
  background-color: $brandDark;

  &__links {
    display: flex;
    flex-direction: column;
  }

  &__link {
    text-decoration: none !important;
    color: $brandLight !important;
    margin: 10px 0;
  }
}

.active {
  color: white !important;
  text-decoration: underline !important;
}

.side-drawer--mobile {
  position: fixed;
  transform: translateX(-100%);
  width: 100%;
  transition: all 300ms ease-in-out;
  padding: 20px 40px;
  background-color: $brandDark;
  z-index: 500;
}

.open {
  transform: translateX(0);
}

.close-button {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  color: $background;
  position: absolute;
  right: 100px;
  cursor: pointer;
}
