@import "../../variables.scss";

.cds-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 10px;

  &__cd {
    position: relative;
    width: 18%;
    min-width: 200px;
    overflow: hidden;
    transition: 500ms;
    margin: 8px;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    &:hover {
      filter: saturate(0);
      cursor: pointer;
      transition: 500ms;
    }
  }

  &__award {
    width: 50px;
    position: absolute;
    left: 0;
    bottom: -3px;
  }
  &__award2 {
    width: 40px;
    position: absolute;
    left: 0;
    bottom: -3px;
  }
}

@media (max-width: 700px) {
  .cds-container {
    padding: 0px;
    &__cd {
      min-width: 100px;
      margin: 4px;
    }
  }
}

@media (max-width: 800px) {
  .cds-container {
    padding: 0px;
    &__cd {
      min-width: 100px;
      margin: 4px;
    }
  }
}
