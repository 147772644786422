.hero {
  position: sticky;
  top: 0;
  z-index: -2;

  & img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    object-position: top right;
  }
}
