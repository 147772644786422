@import "../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;700&family=Noto+Serif+Display:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap");

.ticker {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $brandDark;
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  animation: entrance 1s ease-in-out;

  &__quote {
    font-size: 1.6rem;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &__source {
    font-size: 0.9rem;
    font-weight: bold;
  }
}

@keyframes entrance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
