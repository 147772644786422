@import "../../variables.scss";

.review-panel {
  position: relative;
  margin: 20px 0;
  padding: 20px;
  background-color: $background-light;
  height: 200px;
  overflow: hidden;
  transition: 200ms;

  &__quote {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 5px;
  }

  &__source {
    font-size: 0.9rem;
    font-weight: bold;
  }
}

.open {
  height: fit-content;
}

.reviews-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(255, 153, 102));
  height: 100px;
}

.review-open-button {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 300;
}
